<template>
    <div class="customerContainer">
        <router-view v-if="$store.state.auth.me"></router-view>
    </div>
</template>

<script>

export default {
    name: 'customerContainer'
}

</script>